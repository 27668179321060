import { IStateOrg } from '@/store/modules/org'
import { IOrg } from '@/type/orgs'
import fetchApi from '@/utils/axios'

export default {
  setOrg(state: IStateOrg, org: IOrg) {
    state.org = org
    fetchApi.defaults.headers['Aerial-Target-Org'] = org.id
  },

  setOrgs(state: IStateOrg, orgs: IOrg[]) {
    state.orgs = orgs
  },

  resetOrgs(state: IStateOrg) {
    state.orgs = []
  }
}
