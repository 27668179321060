var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "div",
        { staticClass: "card" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Users.Title")))]),
          _vm.loading
            ? _c(
                "p",
                [
                  _vm._l(4, function(skel, index) {
                    return _c("Skeleton", { key: index })
                  }),
                  _c("Skeleton", { attrs: { type: "half" } })
                ],
                2
              )
            : _c("User")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }