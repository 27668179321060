




















import Vue from 'vue'
import { mapState } from 'vuex'

import Table from '@/components/Card/Thing/Table.vue'
import { IStateThing, ISerial } from '@/store/modules/thing'

export default Vue.extend({
  components: {
    Table
  },
  data() {
    return { loading: false }
  },
  methods: {
    getThing() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.loading = true
      this.$store.dispatch('thing/getThing').finally(() => (this.loading = false))
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    })
  },
  watch: {
    serial: function() {
      this.$store.commit('thing/resetThing')
      this.getThing()
    }
  },
  mounted() {
    this.getThing()
  }
})
