var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      label: "status",
      options: _vm.options.map(function(option) {
        return { status: _vm.$t("Select.Things." + option), value: option }
      }),
      placeholder: _vm.$t("Select.Things.Placeholder"),
      reduce: function(item) {
        return item.value
      },
      value: _vm.filter
    },
    on: { input: _vm.setThingsFilter }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }