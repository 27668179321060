
































import Vue from 'vue'
import { mapGetters } from 'vuex'

import { IStateDiagnostic } from '@/store/modules/diagnostic'

export default Vue.extend({
  computed: {
    ...mapGetters('diagnostic', ['quality'])
  }
})
