




import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { Chart } from 'highcharts-vue'

import { IStateDiagnostic } from '@/store/modules/diagnostic'
import { IStateTheme } from '@/store/modules/theme'
import chartOptions from '@/utils/charts/chartDiagnostic'
import { BAND_INVALID_COLOR, BAND_VALID_COLOR } from '@/utils/constants'
import { IStateThing } from '@/store/modules/thing'

const DEFAULT_RANGE_ABS_MIN = -100
const DEFAULT_RANGE_ABS_MAX = 0

export default Vue.extend({
  data() {
    return {
      chartOptions
    }
  },
  components: {
    Chart
  },
  methods: {
    initGaugeStrength() {
      const chartRef = this.$refs.chartReference
      if (!chartRef) return

      const { rangeAbsoluteMin, rangeAcceptableMin, rangeAcceptableMax, rangeAbsoluteMax } = this.strength
      const chartYAxis = (chartRef as any).chart.yAxis[0]

      chartYAxis.update({
        min: rangeAbsoluteMin || DEFAULT_RANGE_ABS_MIN,
        max: rangeAbsoluteMax || DEFAULT_RANGE_ABS_MAX
      })
      chartYAxis.addPlotBand({
        from: rangeAbsoluteMin,
        to: rangeAcceptableMin,
        color: BAND_INVALID_COLOR
      })
      chartYAxis.addPlotBand({
        from: rangeAcceptableMin,
        to: rangeAcceptableMax,
        color: BAND_VALID_COLOR
      })
      chartYAxis.addPlotBand({
        from: rangeAcceptableMax,
        to: rangeAbsoluteMax,
        color: BAND_INVALID_COLOR
      })
    },
    updateGaugeStrength() {
      const chartRef = this.$refs.chartReference
      if (!chartRef) return

      const { value } = this.strength

      const chartValue = (chartRef as any).chart.series[0].points[0]
      chartValue.update(value)
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapGetters('diagnostic', ['strength'])
  },
  watch: {
    serial: {
      immediate: true,
      handler() {
        this.initGaugeStrength()
      }
    },
    strength: {
      immediate: true,
      handler() {
        this.updateGaugeStrength()
      }
    }
  },
  updated() {
    this.initGaugeStrength()
  }
})
