import { AxiosResponse } from 'axios'

import { APIConnectedClients } from '@/type/things/connectedClients'
import { APIThing } from '@/type/things/thing'
import { APIWifiClients } from '@/type/things/wifiClients'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getClientStatus({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIThing> = await fetchApi.get(`/thing/${serial}`)
      commit('client/setClientMode', response.data.reported.agentWifiOpMode, { root: true })
      commit('client/setReportedClient', response.data.reported.wifiClientMac, { root: true })
      commit('client/setDesiredClient', response.data.desired.wifiClientMac, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async getConnectedClients({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIConnectedClients> = await fetchApi.get(
        `/thing/${serial}/connectedClients`
      )
      commit('client/setConnectedClients', response.data, { root: true })
      commit('client/setClientOptions', response.data, { root: true })
      commit('client/setConnectedClientsFetch', true, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('client/setConnectedClientsFetch', false, { root: true })
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async putSelectedClient({ commit, rootState }: any, client: string) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIWifiClients> = await fetchApi.put(`/thing/${serial}/wifiClients`, {
        wifiClientsMac: [client]
      })
      const desiredClient = response.data.wifiClientsMac[0]
      commit('client/setDesiredClient', desiredClient, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
