import { IStateClient } from '@/store/modules/client'

export default {
  clientMatches: (state: IStateClient) => {
    const { reportedClient, desiredClient } = state
    return reportedClient === desiredClient || desiredClient === null
  },

  onlineClient: (state: IStateClient) => {
    const { connectedClients, reportedClient } = state
    return connectedClients.includes(reportedClient) || reportedClient === 'none'
  }
}
