












import Vue from 'vue'
import { mapState } from 'vuex'

import { IStateClient } from '@/store/modules/client'

export default Vue.extend({
  computed: {
    ...mapState<IStateClient>('client', {
      clientOptions: (state: IStateClient) => state.clientOptions,
      reportedClient: (state: IStateClient) => state.reportedClient
    })
  },
  methods: {
    setWifiClient(client: string | null) {
      this.$store.dispatch('client/putSelectedClient', client || '')
    }
  }
})
