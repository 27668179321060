import { AxiosResponse } from 'axios'

import { APIGroup, APIThingGroups } from '@/type/things/groups'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getGroupsDetails({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const responseThingGroups: AxiosResponse<APIThingGroups> = await fetchApi.get(`thing/${serial}/groups`)
      const groupArray = responseThingGroups.data.groups
      if (!groupArray || !groupArray.length) return

      groupArray.forEach(async (groupId: string) => {
        try {
          const responseGroups: AxiosResponse<APIGroup> = await fetchApi.get(`/group/${groupId}/things`)
          commit('groups/setGroupsDetails', { groupId, things: responseGroups.data.things }, { root: true })
        } catch (error) {
          sentryFetchError(error.response)
          commit('notification/addNotification', error.response, { root: true })
        }
      })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
