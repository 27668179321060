var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "article",
        { staticClass: "card" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Thing.Title")))]),
          _vm.serial
            ? _c(
                "button",
                {
                  staticClass: "loading",
                  class: { "loading--rotating": _vm.loading },
                  on: { click: _vm.getThing }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "redo-alt"] }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.loading
            ? _c(
                "p",
                [
                  _c("Skeleton"),
                  _vm._l(2, function(skel, index) {
                    return _c("Skeleton", {
                      key: index,
                      attrs: { type: "half" }
                    })
                  }),
                  _c("Skeleton"),
                  _c("Skeleton", { attrs: { type: "half" } }),
                  _vm._l(2, function(skel, index) {
                    return _c("Skeleton", { key: index + "two" })
                  }),
                  _c("Skeleton", { attrs: { type: "half" } })
                ],
                2
              )
            : _c("Table")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }