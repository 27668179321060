import { AxiosResponse } from 'axios'

import { APIVersion } from '@/type/version'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getApiVersion({ commit }: any) {
    try {
      const response: AxiosResponse<APIVersion> = await fetchApi.get('/version')
      commit('app/setApiVersion', response.data, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
