
















import Vue from 'vue'
import { mapState } from 'vuex'

import Gauge from '@/components/Card/Signal/Quality/Gauge.vue'
import Table from '@/components/Card/Signal/Quality/Table.vue'
import { IStateThing } from '@/store/modules/thing'
import { REFRESH_SIGNAL_MS } from '@/utils/constants'

export default Vue.extend({
  data() {
    return {
      loading: false,
      timeout: 0
    }
  },
  components: {
    Gauge,
    Table
  },
  methods: {
    getQualityDiagnostic() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.$store.dispatch('diagnostic/getQualityDiagnostic').finally(() => {
        this.loading = false
        this.timeout = setTimeout(this.getQualityDiagnostic, REFRESH_SIGNAL_MS)
      })
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    })
  },
  watch: {
    serial: function() {
      clearTimeout(this.timeout)
      this.$store.commit('diagnostic/resetQualityDiagnostic')
      this.getQualityDiagnostic()
    }
  },
  mounted() {
    clearTimeout(this.timeout)
    this.loading = true
    this.getQualityDiagnostic()
  },
  beforeDestroy() {
    this.$store.commit('diagnostic/resetQualityDiagnostic')
    clearTimeout(this.timeout)
  }
})
