







































































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

import { IStateThings } from '@/store/modules/things'
import { opStatusType } from '@/utils/opStatus'

export default Vue.extend({
  data() {
    return {
      columns: ['Serial', 'OpStatusCode', 'OpStatusType', 'OpStatusMessage', 'Details']
    }
  },
  computed: {
    ...mapState<IStateThings>('things', {
      opStatusTypes: (state: IStateThings) => state.opStatusTypes
    }),
    ...mapGetters('things', ['thingsWithDetails'])
  },
  methods: {
    goToThing(serial: string) {
      this.$store.commit('thing/setSerial', serial)
      this.$router.push({ name: 'thing' })
    }
  },
  filters: {
    capitalize: (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
  }
})
