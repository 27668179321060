import { IUser } from '@/store/modules/user'

export function assertError(errorInfo: object): boolean {
  return Object.entries(errorInfo).length !== 0
}

export function assertUser(user: IUser | object): boolean {
  if (Object.entries(user).length === 0) return false

  const { exp } = user as IUser
  if (!exp) return false

  const nowTsMs = Date.now()
  const expirationTsMs = exp * 1000

  return expirationTsMs > nowTsMs
}

export function parseB64(b64: string | null) {
  let info = {}
  if (!b64) return info

  try {
    info = JSON.parse(atob(b64))
  } catch (error) {
    /* eslint-disable no-console */
    console.debug(error)
  }

  return info
}

export function redirectAuth0(logAction: string): void {
  const port: string = location.port ? `:${location.port}` : ''
  const redirect = encodeURIComponent(`${location.protocol}//${location.hostname}${port}`)

  const url = `https://${process.env.VUE_APP_API_ENV}.aerial-tech.net/api/jiro/mgmt/v1/auth/${logAction}/oauth?redirectUrl=${redirect}`
  return location.replace(url)
}
