















import Vue from 'vue'
import { mapState } from 'vuex'

import { IStateThings } from '@/store/modules/things'
import { OpStatusTypeEnum } from '@/utils/opStatus'

export default Vue.extend({
  data() {
    return {
      options: [
        OpStatusTypeEnum.Error,
        OpStatusTypeEnum.Info,
        OpStatusTypeEnum.OfflineDevice,
        OpStatusTypeEnum.UnsetClient,
        OpStatusTypeEnum.UnsetDevice,
        OpStatusTypeEnum.Valid,
        OpStatusTypeEnum.Warning
      ]
    }
  },
  computed: {
    ...mapState<IStateThings>('things', {
      filter: (state: IStateThings) => state.filter
    })
  },
  methods: {
    setThingsFilter(filter: string | null) {
      this.$store.commit('things/setThingsFilter', filter)
    }
  }
})
