import mutations from '@/store/modules/things/mutations'
import actions from '@/store/modules/things/actions'
import getters from '@/store/modules/things/getters'
import { APIThingInThings } from '@/type/things/things'
import { OpStatusTypeEnum } from '@/utils/opStatus'

export const state: IStateThings = {
  filter: null,
  fleet: {
    error: [],
    info: [],
    offlineDevice: [],
    unsetClient: [],
    unsetDevice: [],
    valid: [],
    warning: []
  },
  loadingThings: false,
  opStatusTypes: OpStatusTypeEnum,
  things: [],
  thingsDetails: []
}

export const things = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateThings {
  filter: IFilter
  fleet: {
    error: string[]
    info: string[]
    offlineDevice: string[]
    unsetClient: string[]
    unsetDevice: string[]
    valid: string[]
    warning: string[]
  }
  loadingThings: boolean
  opStatusTypes: typeof OpStatusTypeEnum
  things: APIThingInThings[] | []
  thingsDetails: IThingInThingsDetails[] | []
}
export interface IThingInThingsDetails {
  serial: string
  opStatusCode: number | null
  opStatusMessage: string
  opStatusType: string
}

export type IFilter = string | null
