







import Vue from 'vue'
import WavesAerialito from '@/components/Animations/WavesAerialito.vue'

export default Vue.extend({
  components: {
    WavesAerialito
  }
})
