import actions from '@/store/modules/diagnostic/actions'
import getters from '@/store/modules/diagnostic/getters'
import mutations from '@/store/modules/diagnostic/mutations'
import { APISignalDiagnostic } from '@/type/things/diagnostic'

export const state: IStateDiagnostic = {
  strength: {},
  quality: {}
}

export const diagnostic = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateDiagnostic {
  strength: APISignalDiagnostic | {}
  quality: APISignalDiagnostic | {}
}
