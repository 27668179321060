export enum OpStatusCodeEnum {
  // UNSET
  UNSET_CLIENT = 0,
  // VALID
  VALID_RUNNING_NORMAL = 1,
  // INFO
  INFO_INITIALIZING = 2,
  INFO_FILLING_BUFFERS = 3,
  INFO_CALIB_COMPLETE = 4,
  INFO_SELECTING_WIFI_CLIENT = 5,
  // WARNING
  WARNING_DEGRADED = 128,
  // ERROR
  ERROR_UNHANDLED = 160,
  ERROR_LOW_PACKET_RATE = 162,
  ERROR_NO_AP_IP_FOUND = 163,
  ERROR_CANNOT_OPEN_CSI_DEV = 164,
  ERROR_NOT_RECEIVING_CSI = 165,
  ERROR_READING_CSI = 166,
  ERROR_DROPPING_PACKETS = 167,
  ERROR_NETWORK_LATENCY = 168
}

export enum OpStatusTypeEnum {
  Error = 'Error',
  Info = 'Info',
  OfflineDevice = 'OfflineDevice',
  UnsetClient = 'UnsetClient',
  UnsetDevice = 'UnsetDevice',
  Valid = 'Valid',
  Warning = 'Warning'
}

export function opStatusMessage(opStatusCode: number) {
  switch (opStatusCode) {
    case OpStatusCodeEnum.ERROR_UNHANDLED:
      return 'Card.Thing.OpStatusMessage.errorUnhandled'

    case OpStatusCodeEnum.ERROR_LOW_PACKET_RATE:
      return 'Card.Thing.OpStatusMessage.errorLowPacketRate'

    case OpStatusCodeEnum.ERROR_NO_AP_IP_FOUND:
      return 'Card.Thing.OpStatusMessage.errorNoApIpFound'

    case OpStatusCodeEnum.ERROR_CANNOT_OPEN_CSI_DEV:
      return 'Card.Thing.OpStatusMessage.errorCannotOpenCsiDev'

    case OpStatusCodeEnum.ERROR_NOT_RECEIVING_CSI:
      return 'Card.Thing.OpStatusMessage.errorNotReceivingCsi'

    case OpStatusCodeEnum.ERROR_READING_CSI:
      return 'Card.Thing.OpStatusMessage.errorReadingCsi'

    case OpStatusCodeEnum.ERROR_DROPPING_PACKETS:
      return 'Card.Thing.OpStatusMessage.errorDroppingPackets'

    case OpStatusCodeEnum.ERROR_NETWORK_LATENCY:
      return 'Card.Thing.OpStatusMessage.errorNetworkLatency'

    case OpStatusCodeEnum.INFO_INITIALIZING:
      return 'Card.Thing.OpStatusMessage.infoInitializing'

    case OpStatusCodeEnum.INFO_FILLING_BUFFERS:
      return 'Card.Thing.OpStatusMessage.infoFillingBuffers'

    case OpStatusCodeEnum.INFO_CALIB_COMPLETE:
      return 'Card.Thing.OpStatusMessage.infoCalibComplete'

    case OpStatusCodeEnum.INFO_SELECTING_WIFI_CLIENT:
      return 'Card.Thing.OpStatusMessage.infoSelectingWifiClient'

    case OpStatusCodeEnum.UNSET_CLIENT:
      return 'Card.Thing.OpStatusMessage.unsetClient'

    case OpStatusCodeEnum.VALID_RUNNING_NORMAL:
      return 'Card.Thing.OpStatusMessage.validRunningNormal'

    case OpStatusCodeEnum.WARNING_DEGRADED:
      return 'Card.Thing.OpStatusMessage.warningDegraded'

    default:
      return 'Card.Thing.OpStatusMessage.default'
  }
}

export function opStatusType(opStatusCode: number) {
  switch (opStatusCode) {
    case OpStatusCodeEnum.ERROR_UNHANDLED:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_LOW_PACKET_RATE:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_NO_AP_IP_FOUND:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_CANNOT_OPEN_CSI_DEV:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_NOT_RECEIVING_CSI:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_READING_CSI:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_DROPPING_PACKETS:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.ERROR_NETWORK_LATENCY:
      return OpStatusTypeEnum.Error

    case OpStatusCodeEnum.INFO_INITIALIZING:
      return OpStatusTypeEnum.Info

    case OpStatusCodeEnum.INFO_FILLING_BUFFERS:
      return OpStatusTypeEnum.Info

    case OpStatusCodeEnum.INFO_CALIB_COMPLETE:
      return OpStatusTypeEnum.Info

    case OpStatusCodeEnum.INFO_SELECTING_WIFI_CLIENT:
      return OpStatusTypeEnum.Info

    case OpStatusCodeEnum.UNSET_CLIENT:
      return OpStatusTypeEnum.UnsetClient

    case OpStatusCodeEnum.VALID_RUNNING_NORMAL:
      return OpStatusTypeEnum.Valid

    case OpStatusCodeEnum.WARNING_DEGRADED:
      return OpStatusTypeEnum.Warning

    default:
      return OpStatusTypeEnum.UnsetClient
  }
}
