import { IStateThings, IFilter } from '@/store/modules/things'
import { APIThings, APIThingsDetails } from '@/type/things/things'
import { opStatusMessage, opStatusType, OpStatusCodeEnum } from '@/utils/opStatus'

export default {
  setThings(state: IStateThings, { things }: APIThings) {
    state.things = things
  },

  resetThings(state: IStateThings) {
    state.things = []
  },

  setThingDetails(state: IStateThings, { things }: APIThingsDetails) {
    things.forEach(thing => {
      if (thing.reported === undefined || thing.reported.opStatus === null) {
        const thingWithDetail = {
          serial: thing.serial,
          opStatusCode: null,
          opStatusMessage: 'Card.Thing.OpStatusMessage.unsetDevice',
          opStatusType: 'UnsetDevice'
        }
        state.thingsDetails = [...state.thingsDetails, thingWithDetail]
      } else if (thing.reported.connected === false) {
        const thingWithDetail = {
          serial: thing.serial,
          opStatusCode: null,
          opStatusMessage: 'Card.Thing.OpStatusMessage.offlineDevice',
          opStatusType: 'OfflineDevice'
        }
        state.thingsDetails = [...state.thingsDetails, thingWithDetail]
      } else {
        const thingWithDetail = {
          serial: thing.serial,
          opStatusCode: thing.reported.opStatus,
          opStatusMessage: opStatusMessage(thing.reported.opStatus),
          opStatusType: opStatusType(thing.reported.opStatus)
        }
        state.thingsDetails = [...state.thingsDetails, thingWithDetail]
      }
    })
  },

  resetThingDetails(state: IStateThings) {
    state.thingsDetails = []
  },

  setThingFleet(state: IStateThings, { things }: APIThingsDetails) {
    things.forEach(thing => {
      const { serial } = thing

      if (thing.reported === undefined || thing.reported.opStatus === null) {
        state.fleet.unsetDevice = [...state.fleet.unsetDevice, serial]
      } else if (thing.reported.connected === false) {
        state.fleet.offlineDevice = [...state.fleet.offlineDevice, serial]
      } else {
        switch (thing.reported && thing.reported.opStatus) {
          case OpStatusCodeEnum.UNSET_CLIENT:
            state.fleet.unsetClient = [...state.fleet.unsetClient, serial]
            break
          case OpStatusCodeEnum.VALID_RUNNING_NORMAL:
            state.fleet.valid = [...state.fleet.valid, serial]
            break
          case OpStatusCodeEnum.INFO_INITIALIZING:
            state.fleet.info = [...state.fleet.info, serial]
            break
          case OpStatusCodeEnum.INFO_FILLING_BUFFERS:
            state.fleet.info = [...state.fleet.info, serial]
            break
          case OpStatusCodeEnum.INFO_CALIB_COMPLETE:
            state.fleet.info = [...state.fleet.info, serial]
            break
          case OpStatusCodeEnum.INFO_SELECTING_WIFI_CLIENT:
            state.fleet.info = [...state.fleet.info, serial]
            break
          case OpStatusCodeEnum.WARNING_DEGRADED:
            state.fleet.warning = [...state.fleet.warning, serial]
            break
          case OpStatusCodeEnum.ERROR_UNHANDLED:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_LOW_PACKET_RATE:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_NO_AP_IP_FOUND:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_CANNOT_OPEN_CSI_DEV:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_NOT_RECEIVING_CSI:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_READING_CSI:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_DROPPING_PACKETS:
            state.fleet.error = [...state.fleet.error, serial]
            break
          case OpStatusCodeEnum.ERROR_NETWORK_LATENCY:
            state.fleet.error = [...state.fleet.error, serial]
            break
          default:
            state.fleet.unsetClient = [...state.fleet.unsetClient, serial]
            break
        }
      }
    })
  },

  resetThingFleet(state: IStateThings) {
    state.fleet.error = []
    state.fleet.info = []
    state.fleet.offlineDevice = []
    state.fleet.unsetClient = []
    state.fleet.unsetDevice = []
    state.fleet.valid = []
    state.fleet.warning = []
  },

  setThingsFilter(state: IStateThings, filter: IFilter) {
    state.filter = filter
  },

  setThingsLoading(state: IStateThings, value: boolean) {
    state.loadingThings = value
  }
}
