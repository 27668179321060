var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.motionStatus && _vm.motionStatus.activityLevel !== null
    ? _c("div", { staticClass: "status" }, [
        _vm.motionStatus.humanMotionDetected
          ? _c("p", { staticClass: "signal signal--human" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("Card.Live.Current")) +
                  " " +
                  _vm._s(_vm.motionTsMs) +
                  ": "
              ),
              _c("span", { staticClass: "uppercase" }, [
                _vm._v(" " + _vm._s(_vm.$t("Card.Live.Human")))
              ])
            ])
          : _c("p", { staticClass: "signal" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("Card.Live.Current")) +
                  " " +
                  _vm._s(_vm.motionTsMs) +
                  ": "
              ),
              _c("span", { staticClass: "uppercase" }, [
                _vm._v(_vm._s(_vm.$t("Card.Live.NoMotion")))
              ])
            ]),
        _vm.lastHumanDate
          ? _c("p", { staticClass: "last-human-motion" }, [
              _vm._v(
                _vm._s(_vm.$t("Card.Live.LastHuman")) +
                  " " +
                  _vm._s(_vm.lastHumanDate)
              )
            ])
          : _vm._e()
      ])
    : _c("p", [_vm._v(_vm._s(_vm.$t("Card.Live.NoMotionAvailable")))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }