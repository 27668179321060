import { AxiosResponse } from 'axios'
import { IStateNotification, INotification } from '@/store/modules/notification'
import { DEFAULT_ERROR_MESSAGE } from '@/utils/constants'

export default {
  addNotification(state: IStateNotification, { config, status }: AxiosResponse) {
    const notification: INotification = {
      title: `${status} - ${config.url}`,
      content: DEFAULT_ERROR_MESSAGE
    }

    state.notifications.push(notification)
  },

  resetNotification(state: IStateNotification) {
    state.notifications = []
  }
}
