import mutations from '@/store/modules/theme/mutations'

export const state: IStateTheme = {
  dark: true
}

export const theme = {
  namespaced: true,
  state,
  mutations
}

// Types
export interface IStateTheme {
  dark: boolean
}
