var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader" },
    [
      _c("WavesAerialito"),
      _c("p", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.$t("Default.Loading")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }