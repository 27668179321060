import { IStateThing } from '@/store/modules/thing'
import { APIThing } from '@/type/things/thing'

export default {
  setSerial(state: IStateThing, serial: string) {
    state.serial = serial
  },

  resetSerial(state: IStateThing) {
    state.serial = null
  },

  setThing(state: IStateThing, thing: APIThing) {
    state.thing = thing
  },

  resetThing(state: IStateThing) {
    state.thing = null
  }
}
