




















import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { redirectAuth0 } from '@/utils/auth'
import { IStateApp } from '@/store/modules/app'
import { IStateDrawer } from '@/store/modules/drawer'

export default Vue.extend({
  computed: {
    ...mapState<IStateApp>('app', {
      appVersion: (state: IStateApp) => state.appVersion
    }),
    ...mapState<IStateDrawer>('drawer', {
      drawer: (state: IStateDrawer) => state.drawer,
      drawers: (state: IStateDrawer) => state.drawers
    })
  },
  methods: {
    ...mapMutations('drawer', ['closeDrawers'])
  }
})
