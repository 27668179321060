
















import Vue from 'vue'

import Client from '@/components/Card/Client/Index.vue'
// import History from '@/components/Card/History/Index.vue'
import Groups from '@/components/Card/Groups/Index.vue'
import Live from '@/components/Card/Live/Index.vue'
import Quality from '@/components/Card/Signal/Quality/Index.vue'
import SelectThing from '@/components/Select/Thing.vue'
import Strength from '@/components/Card/Signal/Strength/Index.vue'
import Thing from '@/components/Card/Thing/Index.vue'
import Users from '@/components/Card/Users/Index.vue'

export default Vue.extend({
  components: {
    Client,
    // History,
    Live,
    Quality,
    SelectThing,
    Strength,
    Thing,
    Users,
    Groups
  }
})
