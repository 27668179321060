

















import Vue from 'vue'
import { mapState } from 'vuex'
import { Chart } from 'highcharts-vue'
import moment from 'moment'

import { IStateActivity } from '@/store/modules/activity'
import { IStateThing } from '@/store/modules/thing'
import { APIMotionStatus } from '@/type/things/motion/status'

export default Vue.extend({
  data() {
    return {
      lastHumanDate: ''
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateActivity>('activity', {
      motionStatus: (state: IStateActivity) => state.motionStatus,
      motionTsMs: (state: IStateActivity) =>
        state.motionStatus && moment(state.motionStatus.timestamp).format('HH:mm:ss')
    })
  },
  watch: {
    serial: function() {
      this.lastHumanDate = ''
    },
    motionStatus: function(motionStatus: APIMotionStatus) {
      if (!motionStatus || !motionStatus.humanMotionDetected) return

      this.lastHumanDate = moment(motionStatus.timestamp).format('HH:mm:ss')
    }
  }
})
