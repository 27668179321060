var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.thing
    ? _c("table", [
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.Serial")))]),
          _c("td", [_vm._v(_vm._s(_vm.thing.serial))])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.Connected")))]),
          _vm.thing.reported.connected
            ? _c(
                "td",
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--valid",
                    attrs: { icon: ["fas", "check-circle"] }
                  })
                ],
                1
              )
            : _c(
                "td",
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--error",
                    attrs: { icon: ["fas", "exclamation-circle"] }
                  })
                ],
                1
              )
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.OpStatusCode")))]),
          _c("td", [_vm._v(_vm._s(_vm.thing.reported.opStatus))])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.OpStatus")))]),
          _c("td", { staticClass: "capitalize" }, [
            _vm._v(_vm._s(_vm.$t("" + _vm.opStatusI18nKey)))
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.AgentMode")))]),
          _c("td", { staticClass: "uppercase" }, [
            _vm._v(_vm._s(_vm.thing.reported.agentWifiOpMode))
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.AgentVersion")))]),
          _c("td", [_vm._v(_vm._s(_vm.thing.reported.agentVersion))])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.Engine")))]),
          _c("td", [_vm._v(_vm._s(_vm.thing.reported.engineVersion))])
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Card.Thing.LocalIp")))]),
          _c("td", [_vm._v(_vm._s(_vm.thing.reported.agentLocalIp))])
        ])
      ])
    : _c("p", [_vm._v(_vm._s(_vm.$t("Card.Signal.NoValue")))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }