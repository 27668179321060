import { AxiosResponse } from 'axios'

import { APIDiagnostic } from '@/type/things/diagnostic'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getQualityDiagnostic({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIDiagnostic> = await fetchApi.get(`/thing/${serial}/diagnostic`)
      commit('diagnostic/setQualityDiagnostic', response.data, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async getStrengthDiagnostic({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIDiagnostic> = await fetchApi.get(`/thing/${serial}/diagnostic`)
      commit('diagnostic/setStrengthDiagnostic', response.data, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
