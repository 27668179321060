












import Vue from 'vue'
import { mapState } from 'vuex'

import User from '@/components/Card/Users/Users.vue'
import { IStateThing } from '@/store/modules/thing'
import fetchApi from '@/utils/axios'

export default Vue.extend({
  data() {
    return {
      loading: false
    }
  },
  components: {
    User
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    })
  },
  methods: {
    getUsers() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.loading = true
      this.$store.dispatch('users/getUsers').finally(() => (this.loading = false))
    }
  },
  watch: {
    serial: function() {
      this.$store.commit('users/resetUsers')
      this.getUsers()
    }
  },
  mounted() {
    this.getUsers()
  },
  beforeDestroy() {
    this.$store.commit('users/resetUsers')
  }
})
