import { AxiosResponse } from 'axios'

import { APIHumanMotionEvents } from '@/type/things/motion/humanMotionEvents'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getHumanMotionEvents({ commit, rootState }: any) {
    const { serial } = rootState.thing

    try {
      const response: AxiosResponse<APIHumanMotionEvents> = await fetchApi.get(
        `/thing/${serial}/motion/history/humanMotionEvents`
      )
      commit('history/setHumanMotionEvents', response.data.history, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
