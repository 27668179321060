import { IStateHistory } from '@/store/modules/history'
import { APIEventInHumanMotionEvents } from '@/type/things/motion/humanMotionEvents'

export default {
  setHumanMotionEvents(state: IStateHistory, humanMotionEvents: APIEventInHumanMotionEvents[]) {
    state.humanMotionEvents = humanMotionEvents
  },

  resetHumanMotionEvents(state: IStateHistory) {
    state.humanMotionEvents = []
  }
}
