var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { class: { "drawer--right--open": _vm.drawer === _vm.drawers.User } },
    [
      _c(
        "button",
        { staticClass: "drawer__close", on: { click: _vm.closeDrawers } },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "times"] } })],
        1
      ),
      _c(
        "div",
        { staticClass: "user" },
        [
          _vm.user.name
            ? _c("div", [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("Drawer.User.Name")))
                ]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.user.name))
                ])
              ])
            : _vm._e(),
          _vm.user.email
            ? _c("div", [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("Drawer.User.Email")))
                ]),
                _c("p", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.user.email))
                ])
              ])
            : _vm._e(),
          _c("div", [
            _c("p", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("Drawer.User.Organization")))
            ]),
            _c("p", { staticClass: "content" }, [_vm._v(_vm._s(_vm.org))])
          ]),
          _vm.orgs.length > 1 ? _c("hr") : _vm._e(),
          _vm.orgs.length > 1 ? _c("SelectOrg") : _vm._e(),
          _c("hr"),
          _c(
            "button",
            { staticClass: "switch", on: { click: _vm.switchTheme } },
            [
              _vm.dark
                ? _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "toggle-on"] }
                  })
                : _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "toggle-off"] }
                  }),
              _vm._v(" " + _vm._s(_vm.$t("Drawer.User.DarkMode")) + " ")
            ],
            1
          ),
          _c(
            "button",
            { staticClass: "logout", on: { click: _vm.logout } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "sign-out-alt"] }
              }),
              _vm._v(" " + _vm._s(_vm.$t("Drawer.User.Logout")) + " ")
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }