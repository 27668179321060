var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Offline")))]),
      _c("td", [_vm._v(_vm._s(_vm.offlineDevice))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.OfflineDevice)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ]),
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Device")))]),
      _c("td", [_vm._v(_vm._s(_vm.unsetDevice))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.UnsetDevice)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ]),
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Unset")))]),
      _c("td", [_vm._v(_vm._s(_vm.unsetClient))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.UnsetClient)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ]),
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Valid")))]),
      _c("td", [_vm._v(_vm._s(_vm.valid))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.Valid)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ]),
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Info")))]),
      _c("td", [_vm._v(_vm._s(_vm.info))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.Info)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ]),
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Warning")))]),
      _c("td", [_vm._v(_vm._s(_vm.warning))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.Warning)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ]),
    _c("tr", [
      _c("td", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Error")))]),
      _c("td", [_vm._v(_vm._s(_vm.error))]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.goToThingsFiltered(_vm.opStatusTypes.Error)
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "arrow-right"] } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }