import { IStateThing } from '@/store/modules/thing/index'
import { APIThing } from '@/type/things/thing'
import { opStatusMessage } from '@/utils/opStatus'

export default {
  opStatusI18nKey: (state: IStateThing) => {
    const opStatusCode = (state.thing as APIThing).reported.opStatus

    return opStatusMessage(opStatusCode)
  }
}
