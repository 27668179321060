









import Vue from 'vue'
import Error, { ErrorEnum } from '@/components/Error.vue'

export default Vue.extend({
  data() {
    return {
      errorEnum: ErrorEnum
    }
  },
  components: {
    Error
  }
})
