import { IStateClient } from '@/store/modules/client'
import { APIConnectedClients } from '@/type/things/connectedClients'

export const clientOptionNone = { client: 'none', value: '' }

export default {
  setReportedClient(state: IStateClient, client: string) {
    state.reportedClient = client
  },

  resetReportedClient(state: IStateClient) {
    state.reportedClient = ''
  },

  setDesiredClient(state: IStateClient, client: string) {
    state.desiredClient = client
  },

  resetDesiredClient(state: IStateClient) {
    state.desiredClient = ''
  },

  setClientMode(state: IStateClient, mode: string) {
    if (state.clientMode === mode) return

    state.clientMode = mode
  },

  resetClientMode(state: IStateClient) {
    state.clientMode = ''
  },

  setConnectedClients(state: IStateClient, connectedClients: APIConnectedClients) {
    state.connectedClients = connectedClients.clients.map(client => client.mac)
  },

  resetConnectedClients(state: IStateClient) {
    state.connectedClients = []
  },

  setConnectedClientsFetch(state: IStateClient, fetchResult: boolean) {
    state.connectedClientsFetch = fetchResult
  },

  setClientOptions(state: IStateClient, connectedClients: APIConnectedClients) {
    const clientOptions = connectedClients.clients.map(client => {
      return { client: client.mac, value: client.mac }
    })

    state.clientOptions = [clientOptionNone, ...clientOptions]
  },

  resetClientOptions(state: IStateClient) {
    state.clientOptions = []
  }
}
