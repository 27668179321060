import { IStateUser, IUser } from '@/store/modules/user'

export default {
  setAuth(state: IStateUser, authenticated: boolean) {
    state.authenticated = authenticated
  },

  resetAuth(state: IStateUser) {
    state.authenticated = false
  },

  setUser(state: IStateUser, user: IUser) {
    state.user = user
  },

  resetUser(state: IStateUser) {
    state.user = null
  }
}
