var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    [
      _c("div", { staticClass: "header__show-menu" }, [
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.openDrawer(_vm.drawers.Nav)
              }
            }
          },
          [_c("font-awesome-icon", { attrs: { icon: ["fas", "bars"] } })],
          1
        )
      ]),
      _c(
        "router-link",
        {
          staticClass: "header__logo",
          attrs: { to: "/" },
          nativeOn: {
            click: function($event) {
              return _vm.closeDrawers($event)
            }
          }
        },
        [
          _c("img", {
            attrs: { alt: "Vue logo", src: require("@/assets/logo-red.png") }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "header__drawer" },
        [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.openDrawer(_vm.drawers.User)
                }
              }
            },
            [
              _c("span", { staticClass: "button--email" }, [
                _vm._v(" " + _vm._s(_vm.userEmail) + " ")
              ]),
              _c("font-awesome-icon", { attrs: { icon: ["fas", "user"] } })
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: { notifications__dot: _vm.hasNotification },
              on: {
                click: function($event) {
                  return _vm.openDrawer(_vm.drawers.Notifications)
                }
              }
            },
            [_c("font-awesome-icon", { attrs: { icon: ["fas", "bell"] } })],
            1
          ),
          _c("SelectLang")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }