var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { dark: _vm.dark }, attrs: { id: "app" } },
    [
      _vm.loading
        ? _c("div", { staticClass: "app-container" }, [_c("Loader")], 1)
        : _vm.error.message != null
        ? _c("Error", {
            attrs: {
              title: "Error.Misc.Title",
              subtitle: "Error.Misc.Subtitle",
              content: _vm.error.message,
              buttonText: "Error.Misc.Button",
              buttonMethod: _vm.errorEnum.Logout
            }
          })
        : _vm.orgs.length === 0
        ? _c("Error", {
            attrs: {
              title: "Error.Orgs.Title",
              subtitle: "Error.Orgs.Subtitle",
              buttonText: "Error.Orgs.Button",
              buttonMethod: _vm.errorEnum.Logout
            }
          })
        : !_vm.authenticated
        ? _c("Error", {
            attrs: {
              title: "Error.Auth.Title",
              subtitle: "Error.Auth.Subtitle",
              buttonText: "Error.Auth.Button",
              buttonMethod: _vm.errorEnum.Logout
            }
          })
        : _c(
            "div",
            { staticClass: "app-container" },
            [_c("Header"), _c("Layout")],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }