import mutations from '@/store/modules/notification/mutations'

export const state: IStateNotification = {
  notifications: []
}

export const notification = {
  namespaced: true,
  state,
  mutations
}

// Types
export interface IStateNotification {
  notifications: INotification[]
}

export interface INotification {
  title: string
  content: string
}
