var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "card" },
    [
      _c("v-select", {
        attrs: {
          label: "serial",
          options: _vm.things,
          placeholder: _vm.$t("Select.Thing.Placeholder"),
          value: _vm.serial
        },
        on: { input: _vm.setSerial }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }