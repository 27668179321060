







































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import SelectClient from '@/components/Select/Client.vue'
import { IStateThing } from '@/store/modules/thing'
import { IStateClient } from '@/store/modules/client'
import { IStateApp } from '@/store/modules/app'

export default Vue.extend({
  data() {
    return { loading: false }
  },
  components: {
    SelectClient
  },
  methods: {
    getConnectedClients() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.loading = true
      this.$store.dispatch('client/getConnectedClients').finally(() => (this.loading = false))
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial,
      connected: (state: IStateThing) => state.thing && state.thing.reported.connected
    }),
    ...mapState<IStateClient>('client', {
      connectedFetch: (state: IStateClient) => state.connectedClientsFetch
    }),
    ...mapGetters('client', ['clientMatches', 'onlineClient'])
  },
  watch: {
    serial: function(serial) {
      this.$store.commit('client/resetConnectedClients')
      this.getConnectedClients()
    }
  },
  mounted() {
    this.getConnectedClients()
  },
  beforeDestroy() {
    this.$store.commit('client/resetConnectedClients')
  }
})
