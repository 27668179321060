var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "article",
        { staticClass: "card" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Signal.Quality.Title")))]),
          _vm.loading
            ? _c(
                "p",
                [
                  _c("Skeleton", { attrs: { type: "gauge" } }),
                  _c("Skeleton", { attrs: { type: "half" } }),
                  _c("Skeleton", { attrs: { type: "half" } })
                ],
                1
              )
            : [_c("Gauge"), _c("Table")]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }