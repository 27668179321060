import moment from 'moment'
import { IStateHistory } from '@/store/modules/history'
import { APIEventInHumanMotionEvents } from '@/type/things/motion/humanMotionEvents'

export default {
  humanMotionHistory: ({ humanMotionEvents }: IStateHistory) => {
    const result = (humanMotionEvents as APIEventInHumanMotionEvents[])
      .map((humanMotionEvent: APIEventInHumanMotionEvents, index: number) => {
        // if first event type is 'stopped', no start data is available so no duration calculation is possible
        if (humanMotionEvent.type === 'motionDetection.humanMotion.stopped' && index > 0) {
          const startDate = moment(humanMotionEvents[index - 1].timestamp).format('HH:mm:ss')

          let secs = Math.round((humanMotionEvent.timestamp - humanMotionEvents[index - 1].timestamp) / 1000)
          if (secs === 0) secs = 1
          const duration = secs <= 59 ? `${secs}sec` : `${Math.floor(secs / 60)}min ${secs % 60}sec`

          return { startDate, duration }
        }
      })
      .filter(event => event !== undefined)
      .reverse()

    return result
  }
}
