import actions from '@/store/modules/org/actions'
import mutations from '@/store/modules/org/mutations'
import { IOrg } from '@/type/orgs'

export const state: IStateOrg = {
  org: {
    id: '',
    displayName: ''
  },
  orgs: []
}

export const org = {
  namespaced: true,
  state,
  actions,
  mutations
}
export interface IStateOrg {
  org: IOrg
  orgs: IOrg[]
}
