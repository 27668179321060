

























import Vue from 'vue'
import { mapState } from 'vuex'

import Table from '@/components/Card/Things/Table.vue'
import Filters from '@/components/Select/ThingsFilter.vue'
import { IStateUser } from '@/store/modules/user'
import { IStateOrg } from '@/store/modules/org'
import { IStateThings } from '@/store/modules/things'

export default Vue.extend({
  components: {
    Table,
    Filters
  },
  computed: {
    ...mapState<IStateThings>('things', {
      loadingThings: (state: IStateThings) => state.loadingThings
    }),
    ...mapState<IStateOrg>('org', {
      org: (state: IStateOrg) => state.org
    })
  },
  methods: {
    reloadThingsDetails() {
      this.$store.commit('things/setThingsLoading', true)
      this.$store.commit('things/resetThingDetails')
      this.$store.commit('things/resetThingFleet')
      this.$store.dispatch('things/getThingDetails').finally(() => {
        this.$store.commit('things/setThingsLoading', false)
      })
    }
  },
  watch: {
    org: function() {
      this.reloadThingsDetails()
    }
  }
})
