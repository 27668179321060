import mutations from '@/store/modules/drawer/mutations'

export enum DrawerEnum {
  Nav = 'nav',
  None = 'none',
  Notifications = 'notifications',
  User = 'user'
}

export const state: IStateDrawer = {
  drawer: DrawerEnum.None,
  drawers: DrawerEnum
}

export const drawer = {
  namespaced: true,
  state,
  mutations
}

// Types
export interface IStateDrawer {
  drawer: string
  drawers: typeof DrawerEnum
}
