import actions from '@/store/modules/thing/actions'
import getters from '@/store/modules/thing/getters'
import mutations from '@/store/modules/thing/mutations'
import { APIThing } from '@/type/things/thing'

export const state: IStateThing = {
  serial: null,
  thing: null
}

export const thing = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateThing {
  serial: ISerial
  thing: APIThing | null
}

export type ISerial = string | null
