import { IStateDiagnostic } from '@/store/modules/diagnostic'
import { APISignalDiagnostic } from '@/type/things/diagnostic'

export default {
  strength: (state: IStateDiagnostic) => {
    const hasStrength = Object.entries(state.strength).length > 0
    if (!hasStrength) return state.strength

    return {
      rangeAbsoluteMax: (state.strength as APISignalDiagnostic).rangeAbsoluteMax / 10,
      rangeAbsoluteMin: (state.strength as APISignalDiagnostic).rangeAbsoluteMin / 10,
      rangeAcceptableMax: (state.strength as APISignalDiagnostic).rangeAcceptableMax / 10,
      rangeAcceptableMin: (state.strength as APISignalDiagnostic).rangeAcceptableMin / 10,
      value: (state.strength as APISignalDiagnostic).value / 10
    }
  },

  quality: (state: IStateDiagnostic) => {
    const hasQuality = Object.entries(state.quality).length > 0
    if (!hasQuality) return state.quality

    return {
      rangeAbsoluteMax: (state.quality as APISignalDiagnostic).rangeAbsoluteMax,
      rangeAbsoluteMin: (state.quality as APISignalDiagnostic).rangeAbsoluteMin,
      rangeAcceptableMax: (state.quality as APISignalDiagnostic).rangeAcceptableMax,
      rangeAcceptableMin: (state.quality as APISignalDiagnostic).rangeAcceptableMin,
      value: (state.quality as APISignalDiagnostic).value
    }
  }
}
