





























import Vue from 'vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

import SelectLang from '@/components/Select/Lang.vue'
import { IStateDrawer } from '@/store/modules/drawer'
import { IStateNotification } from '@/store/modules/notification'
import { IStateUser } from '@/store/modules/user'

export default Vue.extend({
  components: {
    SelectLang
  },
  computed: {
    ...mapState<IStateDrawer>('drawer', {
      drawers: (state: IStateDrawer) => state.drawers
    }),
    ...mapState<IStateNotification>('notification', {
      notification: (state: IStateNotification) => state.notifications,
      hasNotification: (state: IStateNotification) => state.notifications.length > 0
    }),
    ...mapState<IStateUser>('user', {
      userEmail: (state: IStateUser) => state.user && state.user.email
    })
  },
  methods: {
    ...mapMutations('drawer', ['closeDrawers']),
    openDrawer(drawer: string) {
      this.$store.commit('drawer/openDrawer', drawer)
    }
  }
})
