var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Fleet.Title")))]),
      _c(
        "button",
        {
          staticClass: "loading",
          class: { "loading--rotating": _vm.loadingThings },
          attrs: { disabled: _vm.loadingThings },
          on: { click: _vm.reloadThingsDetails }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "redo-alt"] } })],
        1
      ),
      _vm.loadingThings
        ? _c(
            "p",
            [
              _c("Skeleton", { attrs: { type: "table" } }),
              _c("br"),
              _vm._l(12, function(skel, index) {
                return _c("Skeleton", {
                  key: index,
                  attrs: { type: "margin-large" }
                })
              })
            ],
            2
          )
        : [_c("Filters", { staticClass: "filters" }), _c("Table")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }