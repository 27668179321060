var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details" }, [
    _c(
      "h2",
      {
        staticClass: "details__title",
        on: {
          click: function($event) {
            _vm.openList = !_vm.openList
          }
        }
      },
      [
        _c(
          "span",
          { staticClass: "details__caret" },
          [
            _vm.openList
              ? _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "caret-down"] }
                })
              : _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "caret-right"] }
                })
          ],
          1
        ),
        _vm._v(" " + _vm._s(_vm.details.groupId) + " ")
      ]
    ),
    _c(
      "ul",
      {
        staticClass: "details__list",
        class: { "details__list--open": _vm.openList }
      },
      _vm._l(_vm.details.things, function(thing, index) {
        return _c("li", { key: index }, [_vm._v(_vm._s(thing.serial))])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }