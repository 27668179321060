var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "card" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("Select.Org.Title")))]),
      _c("v-select", {
        attrs: {
          label: "displayName",
          options: _vm.orgs,
          placeholder: _vm.$t("Select.Org.Placeholder"),
          value: _vm.org,
          clearable: false
        },
        on: { input: _vm.setOrg }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }