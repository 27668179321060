import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// route level are code-splitting with webpackChunkName
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/thing',
    name: 'thing',
    component: () => import(/* webpackChunkName: "thing" */ '@/views/Thing.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/things',
    name: 'things',
    component: () => import(/* webpackChunkName: "things" */ '@/views/Things.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    // will match everything means 404 error page
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { transitionName: 'slide' }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
