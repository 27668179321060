







import Vue from 'vue'
import Version from '@/components/Card/Version.vue'
import Fleet from '@/components/Card/Fleet/Index.vue'

export default Vue.extend({
  components: {
    Fleet,
    Version
  }
})
