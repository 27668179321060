var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      class: { "drawer--right--open": _vm.drawer === _vm.drawers.Notifications }
    },
    [
      _c(
        "button",
        { staticClass: "drawer__close", on: { click: _vm.closeDrawers } },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "times"] } })],
        1
      ),
      _vm.hasNotification
        ? _c(
            "button",
            {
              staticClass: "drawer__reset",
              on: { click: _vm.resetNotification }
            },
            [_c("font-awesome-icon", { attrs: { icon: ["fas", "trash"] } })],
            1
          )
        : _vm._e(),
      _c(
        "main",
        { staticClass: "notifications" },
        [
          !_vm.hasNotification
            ? _c("p", [_vm._v(_vm._s(_vm.$t("Drawer.Notification.Empty")))])
            : _vm._e(),
          _vm._l(_vm.notifications, function(notification, index) {
            return _c("Notification", {
              key: index,
              attrs: {
                title: notification.title,
                content: notification.content
              }
            })
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }