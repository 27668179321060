import { Chart } from 'highcharts-vue'
import { ACCESSIBILITY_ANNOUNCEMENT_MS, CHART_HEIGHT } from '@/utils/constants'

export default {
  chart: {
    type: 'line',
    height: CHART_HEIGHT,
    animation: (Chart as any).svg,
    backgroundColor: 'transparent',
    marginRight: 10
  },
  title: null,
  series: [
    {
      name: 'Activity level',
      data: [],
      marker: {
        lineWidth: 2,
        lineColor: null,
        radius: 5
      }
    }
  ],
  time: {
    useUTC: true
  },
  accessibility: {
    announceNewData: {
      enabled: true,
      minAnnounceInterval: ACCESSIBILITY_ANNOUNCEMENT_MS,
      announcementFormatter: (allSeries: any, newSeries: any, newPoint: any) => {
        if (newPoint) {
          const date = new Date(newPoint.x)
          return `New motion added at ${date} with value set to ${newPoint.y}`
        }
        return false
      }
    }
  },
  xAxis: {
    type: 'datetime',
    lineColor: 'transparent',
    tickColor: 'transparent',
    labels: {
      style: {
        fontSize: '12px'
      }
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S'
    },
    tickPixelInterval: 150
  },
  yAxis: {
    min: 0,
    softMax: 400,
    labels: {
      style: {
        fontSize: '12px'
      }
    },
    title: {
      text: 'Raw activity level'
    }
  },
  tooltip: {
    headerFormat: '<b>{series.name}</b>: {point.y}<br/>',
    pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}'
  },
  legend: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  }
}
