








import Vue from 'vue'
import { mapState } from 'vuex'
import { Chart } from 'highcharts-vue'
import moment from 'moment'

import { IStateActivity } from '@/store/modules/activity'
import { IStateThing } from '@/store/modules/thing'
import chartOptions from '@/utils/charts/chartLiveView'

export default Vue.extend({
  data() {
    return {
      chartOptions
    }
  },
  components: {
    Chart
  },
  methods: {
    cleanChart() {
      const chartRef = this.$refs.chartReference
      if (!chartRef) return

      let chartSerie = (chartRef as any).chart.series[0]
      chartSerie.setData([])
    },
    updateChart() {
      const motionStatus = this.$store.state.activity.motionStatus
      const chartRef = this.$refs.chartReference
      if (!chartRef || !motionStatus) return

      const { activityLevel, timestamp } = motionStatus
      if (!activityLevel || !timestamp) return

      const chartSerie = (chartRef as any).chart.series[0]
      const shift = chartSerie.data.length > 20

      const timeZoneEffect = new Date(timestamp).getTimezoneOffset() * 60
      const x = new Date(timestamp).getTime() - timeZoneEffect * 1000
      const y = activityLevel

      chartSerie.addPoint([x, y], true, shift)
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateActivity>('activity', {
      motionStatus: (state: IStateActivity) => state.motionStatus
    })
  },
  watch: {
    serial: function() {
      this.cleanChart()
      this.updateChart()
    },
    motionStatus: function() {
      this.updateChart()
    }
  }
})
