import actions from '@/store/modules/client/actions'
import getters from '@/store/modules/client/getters'
import mutations from '@/store/modules/client/mutations'

export const state: IStateClient = {
  clientMode: '',
  clientOptions: [],
  connectedClients: [],
  connectedClientsFetch: null,
  desiredClient: '',
  reportedClient: ''
}

export const client = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

// Types
export interface IStateClient {
  clientMode: string
  clientOptions: IClientOption[]
  connectedClients: string[]
  connectedClientsFetch: boolean | null
  desiredClient: string
  reportedClient: string
}

export type IClientOption = {
  client: string
  value: string
}
