var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
    _vm.subtitle
      ? _c("h2", { staticClass: "subtitle" }, [
          _vm._v(_vm._s(_vm.$t(_vm.subtitle)))
        ])
      : _vm._e(),
    _vm.content ? _c("p", [_vm._v(_vm._s(_vm.content))]) : _vm._e(),
    _c(
      "button",
      { staticClass: "button button--red", on: { click: _vm.btnAction } },
      [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonText)) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }