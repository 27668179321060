var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quality.value
    ? _c("article", [
        _c("table", [
          _c("tr", [
            _c("td", [_vm._v(_vm._s(_vm.$t("Card.Signal.Value")) + ":")]),
            _c("td", [_vm._v(_vm._s(_vm.quality.value))])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v(_vm._s(_vm.$t("Card.Signal.Quality.Status")) + ":")
            ]),
            _c("td", [
              _vm.quality.value < _vm.quality.rangeAcceptableMin
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon icon--valid",
                        attrs: { icon: ["fas", "check-circle"] }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("Card.Signal.Quality.Below")) + " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quality.rangeAcceptableMin <= _vm.quality.value &&
              _vm.quality.value <= _vm.quality.rangeAcceptableMax
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon icon--valid",
                        attrs: { icon: ["fas", "check-circle"] }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("Card.Signal.Quality.Valid")) + " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quality.value > _vm.quality.rangeAcceptableMax
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon icon--error",
                        attrs: { icon: ["fas", "exclamation-circle"] }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("Card.Signal.Quality.Above")) + " "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    : _c("p", [_vm._v(_vm._s(_vm.$t("Card.Signal.NoValue")))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }