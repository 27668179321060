var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "div",
        { staticClass: "card" },
        [
          _c("div", { staticClass: "header" }, [
            _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Live.Title")))])
          ]),
          _c("LiveChart"),
          _c("CurrentStatus")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }