import { AxiosResponse } from 'axios'

import { APIUser, APIUsers } from '@/type/users'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getUsers({ commit, rootState }: any) {
    const { serial } = rootState.thing

    fetchApi
      .get(`/aud/app/thing/${serial}/users`)
      .then((response: AxiosResponse<APIUsers>) => {
        const users = response.data.users

        users.forEach(async (currentUser: any) => {
          try {
            const response: AxiosResponse<APIUser> = await fetchApi.get(`/aud/app/user/${currentUser.uid}`)
            const { user } = response.data
            commit('users/setUsers', user, { root: true })
          } catch (error) {
            sentryFetchError(error.response)
            commit('notification/addNotification', error.response, { root: true })
          }
        })
      })
      .catch(error => commit('notification/addNotification', error.response, { root: true }))
  }
}
