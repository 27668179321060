var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "card" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Version.Title")))]),
    _c("table", [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.$t("Card.Version.App")))]),
        _c("td", [_vm._v(_vm._s(_vm.appVersion))])
      ]),
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.$t("Card.Version.Env")))]),
        _c("td", { staticClass: "capitalize" }, [_vm._v(_vm._s(_vm.appEnv))])
      ]),
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.$t("Card.Version.Org")))]),
        _c("td", { staticClass: "capitalize" }, [_vm._v(_vm._s(_vm.org))])
      ]),
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.$t("Card.Version.Api")))]),
        _vm.loading
          ? _c("td", [_vm._v(_vm._s(_vm.$t("Default.Loading")))])
          : _c("td", [_vm._v(_vm._s(_vm.apiVersion))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }