var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial && _vm.groups.length > 0
    ? _c("div", { staticClass: "card" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Group.Title")))]),
        _vm.loading
          ? _c(
              "p",
              [
                _c("Skeleton"),
                _c("Skeleton", { attrs: { type: "half" } }),
                _c("Skeleton", { attrs: { type: "half" } })
              ],
              1
            )
          : _c(
              "div",
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("Card.Group.ThingGroup")))]),
                _vm._l(_vm.groups, function(group, index) {
                  return _c("GroupDetail", {
                    key: index,
                    attrs: { details: group }
                  })
                })
              ],
              2
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }