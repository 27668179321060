















import Vue from 'vue'
import { mapState } from 'vuex'

import GroupDetail from '@/components/Card/Groups/GroupDetail.vue'
import { IStateGroups } from '@/store/modules/groups'
import { IStateThing } from '@/store/modules/thing'

export default Vue.extend({
  data() {
    return { loading: false }
  },
  components: {
    GroupDetail
  },
  methods: {
    getThingGroups() {
      this.$store.commit('groups/resetGroupDetails')

      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.loading = true
      this.$store.dispatch('groups/getGroupsDetails').finally(() => (this.loading = false))
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateGroups>('groups', {
      groups: (state: IStateGroups) => state.groupsDetails
    })
  },
  watch: {
    serial: {
      immediate: true,
      handler() {
        this.getThingGroups()
      }
    }
  }
})
