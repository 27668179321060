import { IStateDiagnostic } from '@/store/modules/diagnostic'
import { APIDiagnostic } from '@/type/things/diagnostic'

export default {
  setQualityDiagnostic(state: IStateDiagnostic, diagnostic: APIDiagnostic) {
    state.quality = diagnostic.signal.quality
  },

  resetQualityDiagnostic(state: IStateDiagnostic) {
    state.quality = {}
  },

  setStrengthDiagnostic(state: IStateDiagnostic, diagnostic: APIDiagnostic) {
    state.strength = diagnostic.signal.strength
  },

  resetStrengthDiagnostic(state: IStateDiagnostic) {
    state.strength = {}
  }
}
