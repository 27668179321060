


















import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import Nav from '@/components/Drawer/Nav.vue'
import Notifications from '@/components/Drawer/Notifications.vue'
import User from '@/components/Drawer/User.vue'
import Header from '@/components/Layout/Header.vue'
import { IStateDrawer } from '@/store/modules/drawer'

const DEFAULT_TRANSITION = 'fade'

export default Vue.extend({
  components: {
    Nav,
    Notifications,
    User
  },
  data() {
    return {
      transitionName: DEFAULT_TRANSITION
    }
  },
  computed: mapState<IStateDrawer>('drawer', {
    drawer: (state: IStateDrawer) => state.drawer,
    drawers: (state: IStateDrawer) => state.drawers
  }),
  methods: {
    closeDrawers() {
      const { drawer, drawers } = this.$store.state.drawer
      if (drawer === drawers.None) return

      this.$store.commit('drawer/closeDrawers')
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION

      next()
    })
  }
})
