











import Vue from 'vue'
import { mapState } from 'vuex'

import LiveChart from '@/components/Card/Live/Chart.vue'
import CurrentStatus from '@/components/Card/Live/CurrentStatus.vue'
import { IStateActivity } from '@/store/modules/activity'
import { IStateThing } from '@/store/modules/thing'
import { REFRESH_MOTION_MS } from '@/utils/constants'

export default Vue.extend({
  data() {
    return {
      timeout: 0
    }
  },
  components: {
    CurrentStatus,
    LiveChart
  },
  methods: {
    getMotionStatus() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return clearTimeout(this.timeout)

      this.$store.dispatch('activity/getMotionStatus').finally(() => {
        this.timeout = setTimeout(this.getMotionStatus, REFRESH_MOTION_MS)
      })
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    })
  },
  watch: {
    serial: function() {
      clearTimeout(this.timeout)
      this.$store.commit('activity/resetMotionStatus')
      this.getMotionStatus()
    }
  },
  mounted() {
    clearTimeout(this.timeout)
    this.getMotionStatus()
  },
  beforeDestroy() {
    this.$store.commit('activity/resetMotionStatus')
    clearTimeout(this.timeout)
  }
})
