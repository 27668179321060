import actions from '@/store/modules/activity/actions'
import mutations from '@/store/modules/activity/mutations'
import { APIMotionStatus } from '@/type/things/motion/status'

export const state: IStateActivity = {
  motionStatus: null
}

export const activity = {
  namespaced: true,
  state,
  mutations,
  actions
}

// Types
export interface IStateActivity {
  motionStatus: APIMotionStatus | null
}
