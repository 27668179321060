












































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import { IStateThing } from '@/store/modules/thing'

export default Vue.extend({
  computed: {
    ...mapState<IStateThing>('thing', {
      thing: (state: IStateThing) => state.thing
    }),
    ...mapGetters('thing', ['opStatusI18nKey'])
  }
})
