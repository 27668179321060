import actions from '@/store/modules/history/actions'
import getters from '@/store/modules/history/getters'
import mutations from '@/store/modules/history/mutations'
import { APIEventInHumanMotionEvents } from '@/type/things/motion/humanMotionEvents'

export const state: IStateHistory = {
  humanMotionEvents: []
}

export const history = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export interface IStateHistory {
  humanMotionEvents: APIEventInHumanMotionEvents[] | []
}
