















import Vue from 'vue'

export default Vue.extend({
  props: ['details'],
  data() {
    return {
      openList: false
    }
  }
})
