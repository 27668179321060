import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
More(Highcharts)

import { CHART_HEIGHT } from '@/utils/constants'

export default {
  chart: {
    className: 'gauge-chart',
    height: CHART_HEIGHT,
    type: 'gauge',
    backgroundColor: 'transparent',
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false
  },
  title: null,
  pane: {
    startAngle: -150,
    endAngle: 150,
    background: [
      {
        backgroundColor: 'transparent',
        borderColor: 'gray',
        borderWidth: 2,
        outerRadius: '105%'
      }
    ]
  },
  yAxis: {
    minorTickInterval: 'auto',
    minorTickWidth: 1,
    minorTickLength: 5,
    minorTickPosition: 'inside',

    tickPixelInterval: 40,
    tickWidth: 1,
    tickPosition: 'inside',
    tickLength: 13,

    labels: {
      step: 2,
      rotation: 'auto',
      style: {
        fontWeight: 'normal'
      }
    }
  },
  series: [
    {
      data: [0],
      overshoot: 5,
      wrap: false
    }
  ],
  legend: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  tooltip: {
    enabled: false
  }
}
