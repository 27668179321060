var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "waves" }, [
      _c("div", { staticClass: "center" }, [
        _c("img", {
          staticClass: "aerialito",
          attrs: { src: require("@/assets/aerialito-red.svg") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }