import moment from 'moment'
import { IStateActivity } from '@/store/modules/activity'
import { APIMotionStatus } from '@/type/things/motion/status'

export default {
  setMotionStatus(state: IStateActivity, motionsStatus: APIMotionStatus) {
    if (motionsStatus.timestamp === null) {
      const generatedTimestamp = moment()
        .toDate()
        .toISOString()

      motionsStatus.timestamp = generatedTimestamp
    }
    state.motionStatus = motionsStatus
  },

  resetMotionStatus(state: IStateActivity) {
    state.motionStatus = null
  }
}
