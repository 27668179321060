import { IStateDrawer, DrawerEnum } from '@/store/modules/drawer'

export default {
  closeDrawers(state: IStateDrawer) {
    state.drawer = DrawerEnum.None
  },
  openDrawer(state: IStateDrawer, drawer?: DrawerEnum) {
    switch (drawer) {
      case DrawerEnum.Nav:
        state.drawer = DrawerEnum.Nav
        break
      case DrawerEnum.Notifications:
        state.drawer = DrawerEnum.Notifications
        break
      case DrawerEnum.User:
        state.drawer = DrawerEnum.User
        break
      default:
        state.drawer = DrawerEnum.None
    }
  }
}
