var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", [
    _vm.loading
      ? _c("p", [_c("Skeleton")], 1)
      : _c(
          "div",
          [
            _c("table", [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("Card.Client.State")) + ":")]),
                !_vm.connected
                  ? _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon icon--error",
                          attrs: { icon: ["fas", "exclamation-circle"] }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Card.Client.DisconnectedThing")) +
                            " "
                        )
                      ],
                      1
                    )
                  : !_vm.connectedFetch
                  ? _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon icon--error",
                          attrs: { icon: ["fas", "exclamation-circle"] }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Card.Client.ConnectedFetchFail")) +
                            " "
                        )
                      ],
                      1
                    )
                  : !_vm.onlineClient
                  ? _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon icon--error",
                          attrs: { icon: ["fas", "exclamation-circle"] }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Card.Client.DisconnectedClient")) +
                            " "
                        )
                      ],
                      1
                    )
                  : !_vm.clientMatches
                  ? _c("td", [_vm._v(_vm._s(_vm.$t("Default.Updating")))])
                  : _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon icon--valid",
                          attrs: { icon: ["fas", "check-circle"] }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Card.Client.Connected")) + " "
                        )
                      ],
                      1
                    )
              ])
            ]),
            _vm.connected && _vm.connectedFetch ? _c("SelectClient") : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }