import actions from '@/store/modules/groups/actions'
import mutations from '@/store/modules/groups/mutations'

export const state: IStateGroups = {
  groupsDetails: []
}

export const groups = {
  namespaced: true,
  state,
  mutations,
  actions
}

// Types
export interface IStateGroups {
  groupsDetails: IGroupDetails[]
}

export interface IGroupDetails {
  groupId: string
  things: { serial: string }[]
}
