

























import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { IStateApp } from '@/store/modules/app'
import { IStateOrg } from '@/store/modules/org'

export default Vue.extend({
  data() {
    return { loading: false }
  },
  computed: {
    ...mapState<IStateApp>('app', {
      apiVersion: (state: IStateApp) => state.apiVersion,
      appVersion: (state: IStateApp) => state.appVersion,
      appEnv: (state: IStateApp) => state.appEnv
    }),
    ...mapState<IStateOrg>('org', {
      org: (state: IStateOrg) => state.org.displayName
    })
  },
  created: function() {
    this.loading = true
    this.$store.dispatch('app/getApiVersion').finally(() => (this.loading = false))
  }
})
