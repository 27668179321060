import { AxiosResponse } from 'axios'

import { APIThing } from '@/type/things/thing'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getThing({ commit, state }: any) {
    const { serial } = state
    try {
      const response: AxiosResponse<APIThing> = await fetchApi.get(`/thing/${serial}`)
      commit('thing/setThing', response.data, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
