import { AxiosResponse } from 'axios'

import { APIThings, APIThingsDetails } from '@/type/things/things'
import { getQuery } from '@/utils/api'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getThings({ commit }: any) {
    try {
      const response: AxiosResponse<APIThings> = await fetchApi.get(`/things`)
      commit('things/setThings', response.data, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  },

  async getThingDetails({ commit, state }: any) {
    const { offlineDevice, unsetDevice, unsetClient, valid, info, warning, error } = state.fleet

    if (
      error.length === 0 &&
      info.length === 0 &&
      offlineDevice.length === 0 &&
      unsetClient.length === 0 &&
      unsetDevice.length === 0 &&
      valid.length === 0 &&
      warning.length === 0
    ) {
      try {
        const query = getQuery({ details: 'state' })
        const response: AxiosResponse<APIThingsDetails> = await fetchApi.get(`/things${query}`)
        commit('things/setThingDetails', response.data, { root: true })
        commit('things/setThingFleet', response.data, { root: true })
      } catch (error) {
        sentryFetchError(error.response)
        commit('notification/addNotification', error.response, { root: true })
      }
    }
  }
}
