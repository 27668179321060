






































import Vue from 'vue'
import { mapState } from 'vuex'

import { IStateUsers } from '@/store/modules/users'

export default Vue.extend({
  computed: {
    ...mapState<IStateUsers>('users', {
      users: (state: IStateUsers) => state.users,
      hasUsers: (state: IStateUsers) => state.users && state.users.length > 0
    })
  }
})
