import actions from '@/store/modules/app/actions'
import mutations from '@/store/modules/app/mutations'

export const state: IStateApp = {
  apiVersion: null,
  appEnv: process.env.NODE_ENV,
  appVersion: process.env.PACKAGE_VERSION
}

export const app = {
  namespaced: true,
  state,
  mutations,
  actions
}

// Types
export interface IStateApp {
  apiVersion: string | null
  appEnv: string
  appVersion: string
}
