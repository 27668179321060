





















import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import Clients from '@/components/Card/Client/Clients.vue'
import { IStateThing } from '@/store/modules/thing'
import { IStateClient } from '@/store/modules/client'
import { IStateApp } from '@/store/modules/app'
import { REFRESH_CLIENT_MATCHES_MS } from '@/utils/constants'

export default Vue.extend({
  data() {
    return {
      interval: 0,
      loading: false
    }
  },
  components: {
    Clients
  },
  methods: {
    getClientStatus() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.loading = true
      this.$store.dispatch('client/getClientStatus').finally(() => (this.loading = false))
    },
    getConnectedClients() {
      this.loading = true
      this.$store.dispatch('client/getConnectedClients').finally(() => (this.loading = false))
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateThing>('client', {
      clientMode: (state: IStateClient) => state.clientMode
    }),
    ...mapGetters('client', ['clientMatches'])
  },
  watch: {
    serial: function() {
      this.$store.commit('client/resetClientMode')
      this.$store.commit('client/resetReportedClient')
      this.$store.commit('client/resetDesiredClient')

      this.getClientStatus()
    },
    clientMatches: function(currentMatch) {
      const clientMode = this.$store.state.client.clientMode

      if (!currentMatch && clientMode !== 'sta') {
        this.interval = setInterval(() => {
          this.$store.dispatch('client/getClientStatus')
        }, REFRESH_CLIENT_MATCHES_MS)
      } else {
        clearInterval(this.interval)
      }
    }
  },
  mounted() {
    this.getClientStatus()
  },
  beforeDestroy() {
    this.$store.commit('client/resetClientMode')
    this.$store.commit('client/resetReportedClient')
    this.$store.commit('client/resetDesiredClient')

    clearInterval(this.interval)
  }
})
