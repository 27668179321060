






















































import Vue from 'vue'
import { mapState } from 'vuex'

import { IStateThings } from '@/store/modules/things'

export default Vue.extend({
  computed: {
    ...mapState<IStateThings>('things', {
      error: (state: IStateThings) => state.fleet && state.fleet.error.length,
      info: (state: IStateThings) => state.fleet && state.fleet.info.length,
      offlineDevice: (state: IStateThings) => state.fleet && state.fleet.offlineDevice.length,
      unsetClient: (state: IStateThings) => state.fleet && state.fleet.unsetClient.length,
      unsetDevice: (state: IStateThings) => state.fleet && state.fleet.unsetDevice.length,
      valid: (state: IStateThings) => state.fleet && state.fleet.valid.length,
      warning: (state: IStateThings) => state.fleet && state.fleet.warning.length,
      opStatusTypes: (state: IStateThings) => state.opStatusTypes
    })
  },
  methods: {
    goToThingsFiltered(filter: string) {
      this.$store.commit('things/setThingsFilter', filter)
      this.$router.push({ name: 'things' })
    }
  }
})
