var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial && _vm.clientMode !== "sta"
    ? _c(
        "article",
        { staticClass: "card" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Client.Title")))]),
          _vm.serial
            ? _c(
                "button",
                {
                  staticClass: "loading",
                  class: { "loading--rotating": _vm.loading },
                  on: { click: _vm.getConnectedClients }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "redo-alt"] }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.loading
            ? _c(
                "p",
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("Card.Client.State")) +
                      ": " +
                      _vm._s(_vm.$t("Default.Loading")) +
                      " "
                  ),
                  _c("Skeleton")
                ],
                1
              )
            : _c("Clients")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }