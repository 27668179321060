import mutations from '@/store/modules/users/mutations'
import actions from '@/store/modules/users/actions'

export const state: IStateUsers = {
  users: []
}

export const users = {
  namespaced: true,
  state,
  mutations,
  actions
}

// Types
export interface IStateUsers {
  users: IUser[] | []
}

export type IUser = {
  confirmed: boolean
  createdDate: string
  serials: string[]
  uid: string
  username: string
}
