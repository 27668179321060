var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      label: "client",
      clearable: false,
      options: _vm.clientOptions,
      placeholder: _vm.$t("Card.Client.SelectClient"),
      reduce: function(item) {
        return item.value
      },
      value: _vm.reportedClient
    },
    on: { input: _vm.setWifiClient }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }