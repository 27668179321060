var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasUsers
    ? _c(
        "div",
        { staticClass: "container" },
        _vm._l(_vm.users, function(user, index) {
          return _c("table", { key: index }, [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Card.Users.Uid")))]),
              _c("td", [_vm._v(_vm._s(user.uid))])
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Card.Users.UserName")))]),
              _c("td", [_vm._v(_vm._s(user.username))])
            ]),
            _c(
              "tr",
              [
                _c("td", [_vm._v(_vm._s(_vm.$t("Card.Users.Serials")))]),
                _vm._l(user.serials, function(serial, index) {
                  return _c("td", { key: index }, [
                    _vm._v(" " + _vm._s(serial) + " ")
                  ])
                })
              ],
              2
            ),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Card.Users.CreatedAt")))]),
              _c("td", [_vm._v(_vm._s(user.createdDate))])
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Card.Users.Confirmed")))]),
              user.confirmed
                ? _c(
                    "td",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon icon--valid",
                        attrs: { icon: ["fas", "check-circle"] }
                      })
                    ],
                    1
                  )
                : _c(
                    "td",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon icon--error",
                        attrs: { icon: ["fas", "exclamation-circle"] }
                      })
                    ],
                    1
                  )
            ])
          ])
        }),
        0
      )
    : _c("div", [_vm._v(" " + _vm._s(_vm.$t("Card.Users.NoUsers")) + " ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }