import { IGroupDetails, IStateGroups } from '@/store/modules/groups'

export default {
  setGroupsDetails(state: IStateGroups, groupDetail: IGroupDetails) {
    state.groupsDetails = [...state.groupsDetails, groupDetail]
  },

  resetGroupDetails(state: IStateGroups) {
    state.groupsDetails = []
  }
}
