import moment from 'moment'
import { IStateUsers, IUser } from '@/store/modules/users'

export default {
  setUsers(state: IStateUsers, user: IUser) {
    const formatedDate = moment(user.createdDate).format('DD/MM/YYYY - HH:mm:ss')
    user.createdDate = formatedDate

    state.users = [...state.users, user]
  },

  resetUsers(state: IStateUsers) {
    state.users = []
  }
}
