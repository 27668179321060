import { IStateThings } from '@/store/modules/things'

export default {
  thingsWithDetails: (state: IStateThings) => {
    let result = []

    if (state.filter === null) {
      result = state.thingsDetails.sort((a, b) => a['serial'].localeCompare(b['serial']))
    } else {
      result = state.thingsDetails
        .filter(el => el.opStatusType === state.filter)
        .sort((a, b) => a['serial'].localeCompare(b['serial']))
    }

    return result
  }
}
